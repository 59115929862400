<template>
    <div class="wrapper -sala-de-prensa padding-menu -fondo-campo">
        <div class="breadcrumb">
            <div class="container row-end">
                <router-link to="/">Grupo IAN</router-link>
                <router-link to="/" style="pointer-events: none;">{{ $t('contact.title') }}</router-link>
                <router-link :to="$route.path">{{ $t('press_room.title') }}</router-link>
            </div>
        </div>

        <section class="intro-page">
            <div class="container">
                <h1 class="waypoints">{{ $t('press_room.title') }}</h1>
                <img class="waypoints" :src="$t('press_room.main_banner.img')" alt="">
                <div class="green-box">
                    <h2>{{ $t('press_room.main_banner.title') }}</h2>
                    <div v-html="$t('press_room.main_banner.text')"></div>
                </div>
            </div>
        </section>

        <section class="formulario-container">
            <div class="container row-center">
                <h2 class="waypoints">{{ $t('contact.form.title') }}</h2>
                <div v-html="$t('contact.form.text')"></div>
                <formulario></formulario>
            </div>
        </section>
    </div>
</template>

<script>
import formulario from '@/components/formulario-contacto';

export default {
    name: 'sala-de-prensa',
    mounted() {
        setTimeout(() => {
            if (this.$route.hash == "#formulario") {
                const offsetTop = document.querySelector("#formulario").offsetTop;
                scroll({
                    top: offsetTop,
                    behavior: "smooth"
                });
            }
        }, 1000);
    },
    components: {
        'formulario': formulario
    }
}
</script>

<style lang="scss" scoped>
.intro-page {
    img {
        max-width: 100%;
    }
}
</style>